import { atom } from "recoil"

export const showingMeetingForm = atom({
    key: "showingMeetingForm",
    default: false,
})
export const showingWeddingForm = atom({
    key: "showingWeddingForm",
    default: false,
})
export const showingRomanceForm = atom({
    key: "showingRomanceForm",
    default: false,
})
export const showingMobileNav = atom({
    key: "showingMobileNav",
    default: false,
})
export const activeFormImage = atom({
    key: "activeFormImage",
    default: "",
})
export const headerHeight = atom({
    key: "headerHeight",
    default: 100,
})
