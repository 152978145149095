import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { InputItem, InputSelectItem } from "../reusables"
import { ENGAGEMENT_INFO_STEP } from "../romance-form"
import { DoubleRow, Title } from "../styles"
import { REVIEW_STEP, WEDDING_INFO_STEP } from "../weddings-form"

const ContactStep = ({ data, updateInfo, setStep, romance }) => {
    const stepDisabled = useCallback(() => {
        if (Object.values(data).filter(value => value === "").length > 0)
            return true
        if (data.email.indexOf("@") === -1) return true

        return false
    }, [data])

    return (
        <>
            <Title>Contact Information:</Title>
            <InputItem
                label="Primary Contact Name"
                first
                name="name"
                value={data.name}
                updateInput={updateInfo}
            />
            <DoubleRow>
                <InputItem
                    label="Email"
                    first
                    name="email"
                    value={data.email}
                    updateInput={updateInfo}
                />
                <InputItem
                    label="Phone"
                    name="phone"
                    value={data.phone}
                    updateInput={updateInfo}
                />
            </DoubleRow>
            <InputItem
                label="Country"
                name="country"
                value={data.country}
                updateInput={updateInfo}
            />

            <InputSelectItem
                label="Preferred method of communication"
                options={["Phone", "Email"]}
                value={data.preferredCommunication}
                updateInput={updateInfo}
                name="preferredCommunication"
            />
            <MainButton
                disabled={stepDisabled()}
                onClick={() =>
                    setStep(romance ? ENGAGEMENT_INFO_STEP : WEDDING_INFO_STEP)
                }
            >
                Next
            </MainButton>
        </>
    )
}

export default ContactStep
