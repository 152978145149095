import React from "react"
import { IoMdClose } from "react-icons/io"
import { colors } from "../../styles/colors"
import { InputLabel, InputSelect, TextInput, TextArea } from "./styles"
import styled from "styled-components"

export const InputItem = ({
    label,
    first,
    placeholder,
    name,
    value,
    updateInput,
    type = "text",
}) => {
    return (
        <div style={{ width: "100%", marginRight: first ? 10 : 0 }}>
            <InputLabel role="label">{label}</InputLabel>
            <TextInput
                placeholder={placeholder}
                onChange={updateInput}
                name={name}
                value={value}
                type={type}
            />
        </div>
    )
}
export const TextareaItem = ({
    label,
    first,
    placeholder,
    name,
    value,
    updateInput,
    type = "text",
}) => {
    return (
        <div style={{ width: "100%", marginRight: first ? 10 : 0 }}>
            <InputLabel role="label">{label}</InputLabel>
            <TextArea
                placeholder={placeholder}
                onChange={updateInput}
                name={name}
                value={value}
                type={type}
                rows={10}
            />
        </div>
    )
}
export const InputSelectItem = ({
    label,
    first,
    options,
    updateInput,
    name,
    value,
}) => {
    return (
        <div style={{ width: "100%", marginRight: first ? 10 : 0 }}>
            <InputLabel role="label">{label}</InputLabel>
            <InputSelect name={name} onChange={updateInput} value={value}>
                {options.map((el, index) => (
                    <option
                        key={index}
                        style={{ fontFamily: "komet" }}
                        // value={el.value}
                        // disabled={!checkIfVariantAvailable(el.value)}
                    >
                        {el}
                    </option>
                ))}
            </InputSelect>
        </div>
    )
}

export const CloseButton = ({ handleClose, absolute, dark }) => {
    return (
        <div
            onClick={() => handleClose(false)}
            style={{
                zIndex: 99,
                position: absolute ? "absolute" : "fixed",
                top: 20,
                cursor: "pointer",
                right: 20,
            }}
        >
            <IoMdClose color={dark ? colors.primary : "white"} size={40} />
        </div>
    )
}

export const Container = styled.div`
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const Inner = styled.div`
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;

    @media (max-width: 650px) {
        grid-template-columns: 100%;
    }
`
