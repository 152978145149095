import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"
import { colors } from "../../styles/colors"
import { IoIosClose } from "react-icons/io"
import { Flex } from "../reusables"
import { showingMobileNav } from "../../state/atoms"
import { useSetRecoilState } from "recoil"
import { BOOKING_LINK } from "../../project-constants/constants"

const Container = styled(motion.div)`
    padding: 15px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: ${colors.base};
    z-index: 101;
    height: 100vh;
    transition: 0.2 ease;
`
const Nav = styled.nav`
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    z-index: 100;
    /* justify-content: space-between; */
    /* align-items: center; */
`

const MenuItem = styled(Link)`
    font-size: 17px;
    list-style: none;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    color: white;
    font-family: "Komet";
    background: rgba(255, 255, 255, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 10px;
    /* margin-bottom: 5px; */
    margin: 0 20px 5px;
`
const PatternStyle = styled.img`
    width: 100%;
    background: none;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 20px;
    bottom: 1vh;
    z-index: 99;
    stroke-opacity: 0.4;
`

const MobileNavbar = () => {
    const showNav = useSetRecoilState(showingMobileNav)

    useEffect(() => {
        return () => showNav(false)
    }, [showNav])

    return (
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Flex
                onClick={() => showNav(false)}
                align="center"
                justify="center"
                style={{ position: "absolute", top: "3vh", right: 20 }}
            >
                <IoIosClose size={45} color="white" />
            </Flex>
            <Nav>
                {menu.map((item, i) => (
                    <MenuItem key={i} to={item.link}>
                        {item.title}
                    </MenuItem>
                ))}
            </Nav>
            <MenuItem
                as="a"
                target="_blank"
                rel="noreferrer"
                href={BOOKING_LINK}
            >
                Book a Room
            </MenuItem>
            <PatternStyle src={require("../../images/mobile-shells.svg")} />
        </Container>
    )
}

export default MobileNavbar

const menu = [
    {
        title: "Rooms",
        link: "/rooms",
    },
    {
        title: "Romance",
        link: "/romance",
    },
    {
        title: "Activities",
        link: "/activities",
    },
    {
        title: "Golf",
        link: "/golf",
    },
    {
        title: "Property",
        link: "/property",
    },
    {
        title: "Meetings & Events",
        link: "/meetings",
    },
    {
        title: "Eat & Drink",
        link: "/eat-and-drink",
    },
]
