import styled from "styled-components"
import { colors } from "../../styles/colors"
import { Flex } from "../reusables"

export const Title = styled.h1`
    color: white;
    font-size: 32px;
`
export const TextInput = styled.input`
    border: 2px solid ${colors.primary};
    font-size: 16px;
    padding: 20px 15px;
    background: ${colors.input};
    outline: none;
    width: 100%;
    color: white;
    margin-bottom: 25px;

    @media (max-width: 550px) {
        padding: 12px 12px;
    }
`
export const TextArea = styled.textarea`
    border: 2px solid ${colors.primary};
    font-size: 16px;
    padding: 20px 15px;
    background: ${colors.input};
    outline: none;
    width: 100%;
    color: white;
    margin-bottom: 25px;

    @media (max-width: 550px) {
        padding: 12px 12px;
    }
`
export const InputLabel = styled.label`
    font-size: 16px;
    color: white;
    margin-bottom: 15px;
    display: block;
`
export const InputSelect = styled.select`
    border: 2px solid ${colors.primary};
    font-size: 16px;
    padding: 20px 15px;
    background: ${colors.input};
    outline: none;
    width: 100%;
    color: white;
    margin-bottom: 25px;
    font-family: "komet";
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 550px) {
        padding: 12px 12px;
    }
`

export const DoubleRow = styled(Flex)`
    width: 100%;
`
