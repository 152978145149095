import React from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import MediaQuery from "react-responsive"
import { Link } from "gatsby"
import { BOOKING_LINK } from "../../project-constants/constants"
import { FaFacebookF, FaTwitter, FaYoutube, FaPinterest } from "react-icons/fa"
import { RiInstagramLine } from "react-icons/ri"
import PageHeader from "../page-header"
const Container = styled.footer`
    background: ${colors.base};
    margin-top: 50px;
    margin-top: ${props => (props.noMargin ? 0 : "50px")};
    padding: 10vh 20px;
`
const FooterGrid = styled.div`
    display: grid;
    column-gap: 2%;
    row-gap: 100px;
    max-width: 1200px;
    margin: 0 auto;
    grid-template-columns: auto auto auto 25%;

    @media (max-width: 950px) {
        grid-template-columns: 50% 50%;
        column-gap: 20px;
        row-gap: 40px;
    }
`
const PatternStyle = styled.img`
    width: 100%;
    margin: 10vh 0 0 0;
`
const FooterItem = styled.div`
    display: flex;
    flex-direction: column;
`
const Logo = styled.img`
    max-width: 250px;

    @media (max-width: 950px) {
        max-width: 200px;
        width: 100%;
    }
`
const Heading = styled.h5`
    color: ${colors.primary};
    text-transform: uppercase;

    @media (max-width: 550px) {
        font-size: 12px;
    }
`
const FooterLink = styled(Link)`
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    opacity: 0.8;
    font-size: 15px;
    text-decoration: none;

    @media (max-width: 550px) {
        font-size: 12px;
    }
`
const Copy = styled.p`
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    opacity: 0.8;
    font-size: 15px;

    @media (max-width: 550px) {
        font-size: 12px;
    }
`
const Socials = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    color: white;

    a {
        color: white;
        margin-right: 10px;

        :hover {
            color: ${colors.highlight};
        }
    }
`
const Safe = styled.img`
    max-width: 80px;
    width: 100%;
    @media (max-width: 600px) {
        max-width: 60px;
    }
`
const Grid = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
        /* gap: 8px; */
    }
`

const Footer = ({ footerLogos, noMargin }) => {
    return (
        <Container noMargin={noMargin}>
            <FooterGrid>
                <Logo src={require("../../images/icon-logo.svg")} />
                <FooterItem>
                    <Heading>Rooms</Heading>
                    <FooterLink to="/rooms">Standard Rooms</FooterLink>
                    <FooterLink to="/rooms">Superior Rooms</FooterLink>
                    <FooterLink to="/rooms">Suites</FooterLink>
                    <FooterLink to="/rooms">The Garden Cottages</FooterLink>
                </FooterItem>
                <FooterItem>
                    <Heading>Navigation</Heading>
                    <FooterLink to="/romance">Romance</FooterLink>
                    <FooterLink to="/activities">Activities</FooterLink>
                    <FooterLink to="/golf">Golf</FooterLink>
                    <FooterLink to="/property">Property</FooterLink>
                    <FooterLink to="/specials">Specials</FooterLink>
                    <FooterLink to="/meetings">Meetings & Events</FooterLink>
                    <FooterLink to="/eat-and-drink">Eat & Drink</FooterLink>
                </FooterItem>
                <FooterItem>
                    <MediaQuery minDeviceWidth={950}>
                        <a href={BOOKING_LINK} target="_blank" rel="noreferrer">
                            <MainButton>Book Your Room</MainButton>
                        </a>
                    </MediaQuery>
                    <Grid>
                        {footerLogos &&
                            footerLogos.length &&
                            footerLogos.map((logo, index) => (
                                <Safe key={index} src={logo.sourceUrl} />
                            ))}
                    </Grid>
                </FooterItem>
                <FooterItem>
                    <Heading>Address</Heading>
                    <Copy>
                        78-86 Grafton Road,
                        <br />
                        Mount Irvine, Tobago,
                        <br />
                        West Indies 901124
                    </Copy>
                </FooterItem>
                <FooterItem>
                    <Heading>Email</Heading>
                    <Copy>info@mtirvine.com</Copy>
                </FooterItem>
                <FooterItem>
                    <Heading>Phone</Heading>
                    <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        href="tel:+18683667767"
                    >
                        <Copy style={{ marginBottom: 5 }}>
                            +1 (868) 366 7767
                        </Copy>
                    </a>
                    <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        href="tel:+18682981671"
                    >
                        <Copy style={{ marginBottom: 5 }}>
                            +1 (868) 298 1671
                        </Copy>
                    </a>
                    <a
                        style={{ color: "inherit", textDecoration: "none" }}
                        href="tel:+18682981674"
                    >
                        <Copy>+1 (868) 298 1674</Copy>
                    </a>
                </FooterItem>
                <FooterItem>
                    <Heading>Socials</Heading>
                    <Socials>
                        <a
                            style={{ color: "inherit" }}
                            href="https://www.instagram.com/mtirvineresort/"
                        >
                            <RiInstagramLine size={25} />
                        </a>
                        <a
                            style={{ color: "inherit" }}
                            href="https://www.youtube.com/channel/UCkSU34No7yl37PO25uEFFhQ"
                        >
                            <FaYoutube size={25} />
                        </a>
                        <a
                            style={{ color: "inherit" }}
                            href="https://www.facebook.com/MountIrvineBayResort/"
                        >
                            <FaFacebookF size={25} />
                        </a>
                        <a
                            style={{ color: "inherit" }}
                            href="https://twitter.com/mtirvineresort"
                        >
                            <FaTwitter size={25} />
                        </a>
                        <a
                            style={{ color: "inherit" }}
                            href="https://www.pinterest.com/MIBRWeddings/"
                        >
                            <FaPinterest size={25} />
                        </a>
                    </Socials>
                </FooterItem>
            </FooterGrid>
            <PageHeader noMin />
        </Container>
    )
}

export default Footer
