import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { InputItem, InputSelectItem } from "../reusables"
import { DoubleRow, Title } from "../styles"
import { BRIDEGROOM_STEP } from "../weddings-form"

const WeddingInfoStep = ({ data, updateInfo, setStep, viewing }) => {
    const step1Disabled = useCallback(() => {
        //Get rid of values that are optional
        const strippedData = data
        delete strippedData.checkinDate
        delete strippedData.checkoutDate

        if (
            Object.values(strippedData).filter(value => value === "").length > 0
        )
            return true

        return false
    }, [data])

    return (
        <>
            <Title>Tell us more about your destination wedding:</Title>
            <InputItem
                label="Tentative Date"
                name="date"
                type="date"
                value={data.date}
                updateInput={updateInfo}
            />
            <InputSelectItem
                label="Which are you interested in hosting"
                name="weddingType"
                value={data.weddingType}
                updateInput={updateInfo}
                options={[
                    "Rehearsal Dinner",
                    "Ceremony",
                    "Reception",
                    "Cocktail Reception",
                ]}
            />
            <InputItem
                label="About how many of guests? "
                name="guests"
                type="number"
                value={data.guests}
                updateInput={updateInfo}
            />
            <InputSelectItem
                label="Type of ceremony"
                name="ceremonyType"
                value={data.ceremonyType}
                updateInput={updateInfo}
                options={[
                    "Non-denomination",
                    "Christian",
                    "Hindu",
                    "Muslim",
                    "Other",
                ]}
            />
            <InputSelectItem
                label="Do you require accommodation?"
                name="guestRooms"
                updateInput={updateInfo}
                value={data.guestRooms}
                options={["Yes", "No"]}
            />
            {data.guestRooms === "Yes" && (
                <DoubleRow>
                    <InputItem
                        label="Start Date"
                        name="startDate"
                        type="date"
                        first
                        value={data.checkinDate}
                        updateInput={updateInfo}
                    />
                    <InputItem
                        label="End Date"
                        name="endDate"
                        type="date"
                        value={data.checkoutDate}
                        updateInput={updateInfo}
                    />
                </DoubleRow>
            )}
            <MainButton
                disabled={step1Disabled()}
                onClick={() => setStep(BRIDEGROOM_STEP)}
            >
                Next
            </MainButton>
        </>
    )
}

export default WeddingInfoStep
