import { colors } from "./colors";
import styled from 'styled-components';

export const MainButton = styled.button`
    background: ${colors.primary};
    color: white;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 19px;
    padding: 15px 30px;
    letter-spacing: 1.7px;
    cursor: pointer;

    :disabled {
        background: ${colors.highlight};
        opacity: 0.7;
        cursor: not-allowed;
    }
`
export const AltButton = styled.button`
    background: transparent;
    color: white;
    border: 3px solid white;
    outline: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 19px;
    padding: 15px 30px;
    letter-spacing: 1.7px;
    cursor: pointer;

`