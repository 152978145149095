import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { InputItem, InputSelectItem } from "../reusables"
import { DoubleRow, Title } from "../styles"
import { CONTACT_STEP, REVIEW_STEP } from "../weddings-form"

const BrideGroomStep = ({ data, updateInfo, setStep }) => {
    const stepDisabled = useCallback(() => {
        if (Object.values(data).filter(value => value === "").length > 0)
            return true

        return false
    }, [data])

    return (
        <>
            <Title>About the Bride and Groom:</Title>
            <InputSelectItem
                label="Bride's Title"
                options={["Ms.", "Mr."]}
                updateInput={updateInfo}
                name="brideTitle"
                value={data.brideTitle}
            />

            <DoubleRow>
                <InputItem
                    label="Bride's First Name"
                    first
                    name="brideFirstName"
                    value={data.brideFirstName}
                    updateInput={updateInfo}
                />
                <InputItem
                    label="Bride's Last Name"
                    name="brideLastName"
                    updateInput={updateInfo}
                    value={data.brideLastName}
                />
            </DoubleRow>

            <InputSelectItem
                label="Groom's Title"
                options={["Mr.", "Ms."]}
                name="groomTitle"
                updateInput={updateInfo}
                value={data.groomTitle}
            />

            <DoubleRow>
                <InputItem
                    label="Groom's First Name"
                    first
                    name="groomFirstName"
                    updateInput={updateInfo}
                    value={data.groomFirstName}
                />
                <InputItem
                    label="Groom's Last Name"
                    name="groomLastName"
                    updateInput={updateInfo}
                    value={data.groomLastName}
                />
            </DoubleRow>
            <MainButton
                disabled={stepDisabled()}
                onClick={() => setStep(REVIEW_STEP)}
            >
                Next
            </MainButton>
        </>
    )
}

export default BrideGroomStep
