import React from "react"
import styled from "styled-components"
import { colors } from "../styles/colors"
import { Flex } from "./reusables"
import animationData from "../animations/MIBR_04.json"
import Lottie from "react-lottie"
// import Lottie from "lottie-react"

const Container = styled(Flex)`
    background: ${colors.base};
    color: white;
    min-height: 30vh;
    /* padding-top: 10vh; */

    @media (max-width: 800px) {
        padding: 5vh 20px;
        justify-content: center;
        min-height: ${props => props.noMin ? 'initial' : '40vh'};
    }
`
const Title = styled.h1`
    text-align: center;
    text-transform: uppercase;
    font-size: 65px;

    @media (min-width: 900px) {
        /* max-width: 180px; */
        display: none;
    }

    @media (max-width: 800px) {
        font-size: 55px;
    }
`

const PageHeader = ({ title, noMin }) => {

    function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


    const defaultOptions = {
        loop: true,
        autoplay: true,
        // animationData: animationData,
        animationData: require(`../animations/MIBR_0${randomIntFromInterval(1,9)}.json`), //pick a random shell animation based on the naming convention
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    }

    return (
        <Container direction="column" align="center" justify="flex-end" noMin={noMin}>
            {title && <Title>{title}</Title>}
            <Lottie
                options={defaultOptions}
                height={"100%"}
                width={"100%"}
                isStopped={false}
                isPaused={false}
                isClickToPauseDisabled={true}
                speed={0.125}
            />
            {/* <PatternStyle src={require("../images/shell-design.svg")} /> */}
        </Container>
    )
}

export default PageHeader
