import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { REVIEW_STEP } from "../meetings-form"
import { InputItem, InputSelectItem, TextareaItem } from "../reusables"
import { DoubleRow, Title } from "../styles"

const EventInfoStep = ({ data, updateInfo, setStep, viewing }) => {
    const stepDisabled = useCallback(() => {
        //Get rid of values that are optional
        const strippedData = data

        if (Object.values(data).filter(value => value === "").length > 0)
            return true

        return false
    }, [data])

    return (
        <>
            <Title>Event Details:</Title>
            <InputItem
                label="Event Name"
                first
                name="eventName"
                value={data.eventName}
                updateInput={updateInfo}
            />
            <InputSelectItem
                label="Event Type"
                name="eventType"
                value={data.eventType}
                updateInput={updateInfo}
                options={[
                    "Business Meeting",
                    "Conference or Convention",
                    "Corporate Event",
                    "Education or Seminar",
                    "Incentive",
                    "Reception of Holiday Party",
                    "Team Building Event",
                    "Trade of Consumer Show",
                    "Training",
                    "Other",
                ]}
            />
            <InputItem
                label="Start Date"
                name="startDate"
                type="date"
                value={data.startDate}
                updateInput={updateInfo}
            />
            <InputItem
                label="End Date"
                name="endDate"
                type="date"
                value={data.endDate}
                updateInput={updateInfo}
            />

            <InputSelectItem
                label="Does your event need guest rooms?"
                name="guestRooms"
                updateInput={updateInfo}
                value={data.guestRooms}
                options={["Yes", "No"]}
            />
            <InputSelectItem
                label="Does your event need meeting rooms?"
                name="meetingRooms"
                updateInput={updateInfo}
                options={["Yes", "No"]}
                value={data.meetingRooms}
            />
            <TextareaItem
                label="Other comments or requests"
                name="comments"
                updateInput={updateInfo}
                value={data.comments}
            />
            <MainButton
                disabled={stepDisabled()}
                onClick={() => setStep(REVIEW_STEP)}
            >
                Next
            </MainButton>
        </>
    )
}

export default EventInfoStep
