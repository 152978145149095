import React from "react"
import { useState } from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { CloseButton, Container, Inner } from "./reusables"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { activeFormImage, showingMeetingForm } from "../../state/atoms"
import Loader from "react-loader-spinner"
import { Flex } from "../reusables"
import ContactStep from "./meetings/contact-step"
import EventInfoStep from "./meetings/event-info-step"
import emailjs from "emailjs-com"
import { navigate } from "gatsby"
import ReviewStep from "./review-step"
import {
    EMAIL_JS_USER,
    MEETINGS_AND_EVENTS_TEMPLATE_ID,
} from "../../project-constants/constants"

export const CONTACT_STEP = "CONTACT_INFO"
export const REVIEW_STEP = "REVIEW_INFO"
export const EVENT_INFO_STEP = "EVENT_INFO"
const FORM_TYPE = "Meeting"

const MeetingsForm = ({ type }) => {
    const showForm = useSetRecoilState(showingMeetingForm)
    const activeImage = useRecoilValue(activeFormImage)

    const [step, setStep] = useState(CONTACT_STEP)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [contactInfo, setContactInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        country: "",
        organization: "",
        organizationType: "Association",
    })

    const [eventDetails, setEventDetails] = useState({
        eventName: "",
        eventType: "",
        startDate: "",
        endDate: "",
        guestRooms: "Yes",
        meetingRooms: "Yes",
        comments: "...",
    })

    const updateContactInfo = e => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value })
    }
    const updateEventInfo = e => {
        setEventDetails({ ...eventDetails, [e.target.name]: e.target.value })
    }

    const hideAndReset = () => {
        setContactInfo({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            country: "",
            organization: "",
            organizationType: "Association",
        })
        setEventDetails({
            eventName: "",
            eventType: "Business Meeting",
            startDate: "",
            endDate: "",
            guestRooms: "Yes",
            meetingRooms: "Yes",
            comments: "",
        })
        showForm(false)
    }

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)

        const {
            eventName,
            eventType,
            startDate,
            endDate,
            guestRooms,
            meetingRooms,
            comments,
        } = eventDetails
        const {
            name,
            email,
            phone,
            country,
            organization,
            organizationType,
        } = contactInfo

        const templateParams = {
            type: FORM_TYPE,
            name,
            email,
            phone,
            country,
            organization,
            organizationType,
            eventName,
            eventType,
            startDate,
            endDate,
            guestRooms,
            meetingRooms,
            comments,
        }

        sendEmail(templateParams)

        setTimeout(() => {
            setSubmitted(true)
            setLoading(false)
        }, 2500)
    }

    const sendEmail = templateParams => {
        emailjs
            .send(
                "gmail",
                MEETINGS_AND_EVENTS_TEMPLATE_ID,
                templateParams,
                EMAIL_JS_USER
            )
            .then(
                response => {
                    // navigate("/thank-you")
                    setSubmitted(true)
                    // console.log("SUCCESS!", response.status, response.text)
                },
                err => {
                    // this.setState({ isLoading: false })
                    console.log("FAILED...", err)
                }
            )
    }

    if (submitted) {
        return (
            <Container>
                {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
                <Inner>
                    <Cover>
                        <CoverImage
                            src={activeImage}
                        />
                    </Cover>
                    <FormContainer id="meetingForm">
                        <Form onSubmit={handleSubmit}>
                            <Flex
                                direction="column"
                                align="center"
                                justify="center"
                            >
                                <Title>Your request has been submitted!</Title>
                                <p style={{ color: "white" }}>
                                    Someone from our team will be in contact
                                    with you soon
                                </p>
                                <MainButton onClick={() => hideAndReset()}>
                                    Thanks!
                                </MainButton>
                            </Flex>
                        </Form>
                    </FormContainer>
                </Inner>
            </Container>
        )
    }

    return (
        <Container>
            {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
            <CloseButton handleClose={showForm} />
            <Inner>
                <Cover>
                    <CoverImage
                        src={activeImage}
                    />
                </Cover>
                <FormContainer id="meetingForm">
                    <Form onSubmit={handleSubmit}>
                        {step === CONTACT_STEP && (
                            <ContactStep
                                data={contactInfo}
                                updateInfo={updateContactInfo}
                                setStep={setStep}
                            />
                        )}
                        {step === EVENT_INFO_STEP && (
                            <EventInfoStep
                                data={eventDetails}
                                updateInfo={updateEventInfo}
                                setStep={setStep}
                            />
                        )}
                        {step === REVIEW_STEP && (
                            <ReviewStep
                                setStep={setStep}
                                loading={loading}
                                data={[
                                    {
                                        data: contactInfo,
                                        step: CONTACT_STEP,
                                        title: "Contact",
                                    },
                                    {
                                        data: eventDetails,
                                        step: EVENT_INFO_STEP,
                                        title: "Event",
                                    },
                                ]}
                            />
                        )}
                        {loading && (
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={50}
                                width={50}
                            />
                        )}
                        {/* <MainButton>Next</MainButton> */}
                    </Form>
                </FormContainer>
            </Inner>
        </Container>
    )
}

export default MeetingsForm

const Cover = styled.div`
    background: white;
    height: 100%;
    position: relative;
    overflow: hidden;
`
const CoverImage = styled.img`
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    height: 100%;
`
const Title = styled.h1`
    color: white;
    font-size: 32px;
`
const FormContainer = styled.div`
    background: ${colors.base};
    width: 100%;
    z-index: 2;
    position: relative;
    padding: 80px 40px;
    height: 100%;
    overflow-y: scroll;
`
const PatternStyle = styled.img`
    width: 100%;
    background: none;
    bottom: 10px;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    right: 5px;
`
const Form = styled.form`
    width: 100%;
    z-index: 1;
`
const EditButton = styled.button`
    background: none;
    border: none;
    text-decoration: underline;
    color: ${colors.primary};
    font-size: 20px;
    margin-top: 15px;
`
