import React, { useState } from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import {
    activeFormImage,
    showingRomanceForm,
    showingWeddingForm,
} from "../../state/atoms"
import Loader from "react-loader-spinner"
import { Flex } from "../reusables"
import BrideGroomStep from "./weddings/bridegroom-step"
import WeddingInfoStep from "./weddings/wedding-info-step"
import ContactStep from "./weddings/contact-step"
import emailjs from "emailjs-com"
import { navigate } from "gatsby"
import { CloseButton, Container, Inner } from "./reusables"
import ReviewStep from "./review-step"
import {
    EMAIL_JS_USER,
    WEDDINGS_TEMPLATE_ID,
} from "../../project-constants/constants"

export const WEDDING_INFO_STEP = "WEDDING_INFO"
export const BRIDEGROOM_STEP = "BRIDE_GROOM"
export const CONTACT_STEP = "CONTACT_INFO"
export const REVIEW_STEP = "REVIEW_INFO"
const FORM_TYPE = "Wedding"

const WeddingsForm = ({ type }) => {
    const showForm = useSetRecoilState(showingWeddingForm)
    const activeImage = useRecoilValue(activeFormImage)

    const [step, setStep] = useState(CONTACT_STEP)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [contactInfo, setContactInfo] = useState({
        name: "",
        phone: "",
        email: "",
        country: "",
        preferredCommunication: "Phone",
    })

    const [weddingDetails, setWeddingDetails] = useState({
        date: "",
        weddingType: "",
        guests: 0,
        ceremonyType: "Non-denomination",
        guestRooms: "Yes",
        checkinDate: "",
        checkoutDate: "",
    })

    const [coupleInfo, setCoupleInfo] = useState({
        brideTitle: "Ms.",
        brideFirstName: "",
        brideLastName: "",
        groomTitle: "Mr.",
        groomFirstName: "",
        groomLastName: "",
    })

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)

        const {
            date,
            weddingType,
            guests,
            ceremonyType,
            guestRooms,
            checkinDate,
            checkoutDate,
        } = weddingDetails
        const {
            name,
            email,
            phone,
            country,
            preferredCommunication,
        } = contactInfo
        const {
            brideTitle,
            brideFirstName,
            brideLastName,
            groomFirstName,
            groomLastName,
            groomTitle,
        } = coupleInfo

        const templateParams = {
            type: FORM_TYPE,
            date,
            weddingType,
            guests,
            ceremonyType,
            guestRooms,
            checkinDate,
            checkoutDate,
            brideTitle,
            brideFirstName,
            brideLastName,
            groomTitle,
            groomLastName,
            groomFirstName,
            name,
            phone,
            email,
            country,
            preferredCommunication,
        }

        sendEmail(templateParams)

        setTimeout(() => {
            setSubmitted(true)
            setLoading(false)
        }, 2500)
    }

    const updateContactInfo = e => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value })
    }
    const updateCoupleInfo = e => {
        setCoupleInfo({ ...coupleInfo, [e.target.name]: e.target.value })
    }
    const updateWeddingInfo = e => {
        setWeddingDetails({
            ...weddingDetails,
            [e.target.name]: e.target.value,
        })
    }

    const hideAndReset = () => {
        setCoupleInfo({
            brideTitle: "Ms.",
            brideFirstName: "",
            brideLastName: "",
            groomTitle: "Mr.",
            groomFirstName: "",
            groomLastName: "",
        })
        setWeddingDetails({
            date: "",
            weddingType: "",
            guests: 0,
            ceremonyType: "",
            guestRooms: "",
            checkinDate: "",
            checkoutDate: "",
        })
        setContactInfo({
            name: "",
            phone: "",
            email: "",
            country: "",
            preferredCommunication: "Phone",
        })
        showForm(false)
    }

    const sendEmail = templateParams => {
        emailjs
            .send("gmail", WEDDINGS_TEMPLATE_ID, templateParams, EMAIL_JS_USER)
            .then(
                response => {
                    setSubmitted(true)
                    // navigate("/thank-you")
                    // console.log("SUCCESS!", response.status, response.text)
                },
                err => {
                    // this.setState({ isLoading: false })
                    console.log("FAILED...", err)
                }
            )
    }

    if (submitted) {
        return (
            <Container>
                <CloseButton handleClose={showForm} />

                {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
                <Inner>
                    <Cover>
                        <CoverImage src={activeImage} />
                    </Cover>
                    <FormContainer id="meetingForm">
                        <Form onSubmit={handleSubmit}>
                            <Flex
                                direction="column"
                                align="center"
                                justify="center"
                            >
                                <Title>Your request has been submitted!</Title>
                                <p style={{ color: "white" }}>
                                    Someone from our team will be in contact
                                    with you soon
                                </p>
                                <MainButton onClick={() => hideAndReset()}>
                                    Thanks!
                                </MainButton>
                            </Flex>
                        </Form>
                    </FormContainer>
                </Inner>
            </Container>
        )
    }

    return (
        <Container>
            <CloseButton handleClose={showForm} />
            {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
            <Inner>
                <Cover>
                    <CoverImage src={activeImage} />
                </Cover>
                <FormContainer id="meetingForm">
                    <Form onSubmit={handleSubmit}>
                        {step === WEDDING_INFO_STEP && (
                            <WeddingInfoStep
                                data={weddingDetails}
                                updateInfo={updateWeddingInfo}
                                setStep={setStep}
                            />
                        )}
                        {step === BRIDEGROOM_STEP && (
                            <BrideGroomStep
                                data={coupleInfo}
                                updateInfo={updateCoupleInfo}
                                setStep={setStep}
                            />
                        )}
                        {step === CONTACT_STEP && (
                            <ContactStep
                                data={contactInfo}
                                updateInfo={updateContactInfo}
                                setStep={setStep}
                            />
                        )}
                        {step === REVIEW_STEP && (
                            <ReviewStep
                                setStep={setStep}
                                loading={loading}
                                data={[
                                    {
                                        data: weddingDetails,
                                        step: WEDDING_INFO_STEP,
                                        title: "Wedding",
                                    },
                                    {
                                        data: coupleInfo,
                                        step: BRIDEGROOM_STEP,
                                        title: "Couple",
                                    },
                                    {
                                        data: contactInfo,
                                        step: CONTACT_STEP,
                                        title: "Contact",
                                    },
                                ]}
                            />
                        )}
                        {loading && (
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={50}
                                width={50}
                            />
                        )}
                        {/* <MainButton>Next</MainButton> */}
                    </Form>
                </FormContainer>
            </Inner>
        </Container>
    )
}

export default WeddingsForm

const Cover = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: ${colors.base};

    @media (max-width: 650px) {
        display: none;
    }
`
const CoverImage = styled.img`
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    height: 100%;
`
const Title = styled.h1`
    color: white;
    font-size: 32px;
`
const FormContainer = styled.div`
    background: ${colors.base};
    width: 100%;
    z-index: 2;
    position: relative;
    padding: 80px 40px;
    height: 100%;
    overflow-y: scroll;
`
const PatternStyle = styled.img`
    width: 100%;
    background: none;
    bottom: 10px;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    right: 5px;
`
const Form = styled.form`
    width: 100%;
    z-index: 1;
`
const EditButton = styled.button`
    background: none;
    border: none;
    text-decoration: underline;
    color: ${colors.primary};
    font-size: 20px;
    margin-top: 15px;
`
