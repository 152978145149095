import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { EVENT_INFO_STEP } from "../meetings-form"
import { InputItem, InputSelectItem } from "../reusables"
import { DoubleRow, Title } from "../styles"

const ContactStep = ({ data, updateInfo, setStep }) => {
    const stepDisabled = useCallback(() => {
        if (Object.values(data).filter(value => value === "").length > 0)
            return true
        if (data.email.indexOf("@") === -1) return true

        return false
    }, [data])

    return (
        <>
            <Title>Contact Information:</Title>
            <DoubleRow>
                <InputItem
                    label="First Name"
                    first
                    name="firstName"
                    value={data.firstName}
                    updateInput={updateInfo}
                />
                <InputItem
                    label="Last Name"
                    name="lastName"
                    value={data.lastName}
                    updateInput={updateInfo}
                />
            </DoubleRow>
            <DoubleRow>
                <InputItem
                    label="Email"
                    first
                    name="email"
                    value={data.email}
                    updateInput={updateInfo}
                />
                <InputItem
                    label="Phone"
                    name="phone"
                    value={data.phone}
                    updateInput={updateInfo}
                />
            </DoubleRow>
            <InputItem
                label="Country"
                name="country"
                value={data.country}
                updateInput={updateInfo}
            />
            <InputItem
                label="Organisation Name"
                name="organization"
                value={data.organization}
                updateInput={updateInfo}
            />
            <InputSelectItem
                label="Type of Organisation"
                name="organizationType"
                value={data.organizationType}
                updateInput={updateInfo}
                options={[
                    "Association",
                    "Corporate",
                    "Non-Profit",
                    "Pharmaceutical",
                    "Education",
                    "Government",
                    "Military",
                    "Religous",
                    "Social",
                    "Other",
                ]}
            />
            <MainButton
                disabled={stepDisabled()}
                onClick={() => setStep(EVENT_INFO_STEP)}
            >
                Next
            </MainButton>
        </>
    )
}

export default ContactStep
