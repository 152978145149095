import React, { useState } from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import scrollTo from "gatsby-plugin-smoothscroll"
import { useRecoilValue, useSetRecoilState } from "recoil"
import {
    activeFormImage,
    showingMeetingForm,
    showingRomanceForm,
} from "../../state/atoms"
import Loader from "react-loader-spinner"
import { Flex } from "../reusables"
import BrideGroomStep from "./weddings/bridegroom-step"
import WeddingInfoStep from "./weddings/wedding-info-step"
import ContactStep from "./weddings/contact-step"
import emailjs from "emailjs-com"
import { navigate } from "gatsby"
import { IoMdClose } from "react-icons/io"
import { CloseButton, Container, Inner } from "./reusables"
import EngagementInfoStep from "./romance/engagement-info-step"
import ReviewStep from "./review-step"
import {
    EMAIL_JS_USER,
    WEDDINGS_TEMPLATE_ID,
} from "../../project-constants/constants"

export const ENGAGEMENT_INFO_STEP = "ENGAGEMENT_INFO"
export const CONTACT_STEP = "CONTACT_INFO"
export const REVIEW_STEP = "REVIEW_INFO"
const FORM_TYPE = "Engagement"

const RomanceForm = ({ type }) => {
    const showForm = useSetRecoilState(showingRomanceForm)
    const activeImage = useRecoilValue(activeFormImage)

    const [step, setStep] = useState(CONTACT_STEP)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const [contactInfo, setContactInfo] = useState({
        name: "",
        phone: "",
        email: "",
        country: "",
        preferredCommunication: "Phone",
    })

    const [engagementDetails, setEngagementDetails] = useState({
        date: "",
        location: "Private Sunset Dinner on the Beach",
        guestRooms: "Yes",
        placesToExplore: [],
        checkinDate: "",
        checkoutDate: "",
        comments: "",
    })

    const handleSubmit = e => {
        e.preventDefault()
        setLoading(true)

        const {
            date,
            location,
            guestRooms,
            checkinDate,
            placesToExplore,
            checkoutDate,
            comments,
        } = engagementDetails
        const {
            name,
            email,
            phone,
            country,
            preferredCommunication,
        } = contactInfo

        const templateParams = {
            type: FORM_TYPE,
            date,
            location,
            guestRooms,
            checkinDate,
            checkoutDate,
            comments,
            name,
            phone,
            email,
            country,
            preferredCommunication,
        }

        sendEmail(templateParams)

        setTimeout(() => {
            setSubmitted(true)
            setLoading(false)
        }, 2500)
    }

    const updateContactInfo = e => {
        setContactInfo({ ...contactInfo, [e.target.name]: e.target.value })
    }
    const updateEngagementInfo = e => {
        setEngagementDetails({
            ...engagementDetails,
            [e.target.name]: e.target.value,
        })
    }

    const hideAndReset = () => {
        setEngagementDetails({
            date: "",
            location: "Private Sunset Dinner on the Beach",
            guestRooms: "Yes",
            placesToExplore: [],
            checkinDate: "",
            checkoutDate: "",
            comments: "",
        })
        setContactInfo({
            name: "",
            phone: "",
            email: "",
            country: "",
            preferredCommunication: "Phone",
        })
        showForm(false)
    }

    const sendEmail = templateParams => {
        emailjs
            .send("gmail", WEDDINGS_TEMPLATE_ID, templateParams, EMAIL_JS_USER)
            .then(
                response => {
                    setSubmitted(true)
                    // navigate("/thank-you")
                    // console.log("SUCCESS!", response.status, response.text)
                },
                err => {
                    // this.setState({ isLoading: false })
                    console.log("FAILED...", err)
                }
            )
    }

    if (submitted) {
        return (
            <Container>
                <CloseButton handleClose={showForm} />

                {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
                <Inner>
                    <Cover>
                        <CoverImage src={activeImage} />
                    </Cover>
                    <FormContainer id="meetingForm">
                        <Form onSubmit={handleSubmit}>
                            <Flex
                                direction="column"
                                align="center"
                                justify="center"
                            >
                                <Title>Your request has been submitted!</Title>
                                <p style={{ color: "white" }}>
                                    Someone from our team will be in contact
                                    with you soon
                                </p>
                                <MainButton onClick={() => hideAndReset()}>
                                    Thanks!
                                </MainButton>
                            </Flex>
                        </Form>
                    </FormContainer>
                </Inner>
            </Container>
        )
    }

    return (
        <Container>
            {/* <PatternStyle src={require('../../images/shell-design.svg')} /> */}
            <CloseButton handleClose={showForm} />
            <Inner>
                <Cover>
                    <CoverImage src={activeImage} />
                </Cover>
                <FormContainer id="meetingForm">
                    <Form onSubmit={handleSubmit}>
                        {step === ENGAGEMENT_INFO_STEP && (
                            <EngagementInfoStep
                                data={engagementDetails}
                                updateInfo={updateEngagementInfo}
                                setStep={setStep}
                                setEngagementDetails={setEngagementDetails}
                            />
                        )}
                        {step === CONTACT_STEP && (
                            <ContactStep
                                data={contactInfo}
                                updateInfo={updateContactInfo}
                                setStep={setStep}
                                romance
                            />
                        )}
                        {step === REVIEW_STEP && (
                            <ReviewStep
                                setStep={setStep}
                                loading={loading}
                                data={[
                                    {
                                        data: engagementDetails,
                                        step: ENGAGEMENT_INFO_STEP,
                                        title: "Engagement",
                                    },
                                    {
                                        data: contactInfo,
                                        step: CONTACT_STEP,
                                        title: "Event",
                                    },
                                ]}
                            />
                        )}
                        {loading && (
                            <Loader
                                type="TailSpin"
                                color={colors.primary}
                                height={50}
                                width={50}
                            />
                        )}
                        {/* <MainButton>Next</MainButton> */}
                    </Form>
                </FormContainer>
            </Inner>
        </Container>
    )
}

export default RomanceForm

const Cover = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    background: ${colors.base};

    @media (max-width: 650px) {
        display: none;
    }
`
const CoverImage = styled.img`
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    object-fit: cover;
    z-index: 0;
    height: 100%;
`
const Title = styled.h1`
    color: white;
    font-size: 32px;
`
const FormContainer = styled.div`
    background: ${colors.base};
    width: 100%;
    z-index: 2;
    position: relative;
    padding: 80px 40px;
    height: 100%;
    overflow-y: scroll;
`
const PatternStyle = styled.img`
    width: 100%;
    background: none;
    bottom: 10px;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    right: 5px;
`
const Form = styled.form`
    width: 100%;
    z-index: 1;
`
const EditButton = styled.button`
    background: none;
    border: none;
    text-decoration: underline;
    color: ${colors.primary};
    font-size: 20px;
    margin-top: 15px;
`
