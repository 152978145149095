import React, { useCallback } from "react"
import { MainButton } from "../../../styles/buttons"
import { InputItem, InputSelectItem, TextareaItem } from "../reusables"
import { CONTACT_STEP, REVIEW_STEP } from "../romance-form"
import { DoubleRow, InputLabel, Title } from "../styles"
import { BRIDEGROOM_STEP } from "../weddings-form"

const EngagementInfoStep = ({
    data,
    updateInfo,
    setStep,
    viewing,
    setEngagementDetails,
}) => {
    console.log(data)
    const stepDisabled = useCallback(() => {
        //Get rid of values that are optional
        const strippedData = data
        delete strippedData.checkinDate
        delete strippedData.checkoutDate
        delete strippedData.comments
        delete strippedData.placesToExplore

        if (Object.values(data).filter(value => value === "").length > 0)
            return true

        return false
    }, [data])

    const handleCheckboxToggle = e => {
        console.log(e.target)
        console.log(data)
        if (data.placesToExplore.findIndex(e.target.name) !== -1) {
            //remove it
        } else {
            //Add it
            setEngagementDetails({
                ...data,
                placesToExplore: [...data.placesToExplore, e.target.name],
            })
            console.log(data)
        }
    }

    return (
        <>
            <Title>Tell us more about your proposal:</Title>
            <InputItem
                label="Tentative date"
                name="date"
                type="date"
                value={data.date}
                updateInput={updateInfo}
            />
            <InputSelectItem
                label="Location for the proposal"
                name="location"
                value={data.location}
                updateInput={updateInfo}
                options={[
                    "Private Sunset Dinner on the Beach",
                    "Intimate Dinner at the Sugar Mill Restaurant",
                    "Golf Gazebo",
                    "Seaview Terrace",
                    "Other",
                ]}
            />
            <InputSelectItem
                label="Do you require accommodation?"
                name="guestRooms"
                updateInput={updateInfo}
                value={data.guestRooms}
                options={["Yes", "No"]}
            />
            {data.guestRooms === "Yes" && (
                <DoubleRow>
                    <InputItem
                        label="Start Date"
                        name="startDate"
                        type="date"
                        first
                        value={data.checkinDate}
                        updateInput={updateInfo}
                    />
                    <InputItem
                        label="End Date"
                        name="endDate"
                        type="date"
                        value={data.checkoutDate}
                        updateInput={updateInfo}
                    />
                </DoubleRow>
            )}
            {/* <InputLabel>
                We would like to explore (please add to my package)
            </InputLabel>
            {placesToExplore.map((place, index) => (
                <>
                    <input
                        key={index}
                        type="checkbox"
                        id={place}
                        name={place}
                        style={{ marginRight: 15 }}
                        value={
                            data.placesToExplore.length
                                ? data.placesToExplore.includes(place)
                                : false
                        }
                        onChange={handleCheckboxToggle}
                    />
                    <label
                        for="vehicle1"
                        style={{ display: "inline-block", color: "white" }}
                    >
                        {place}
                    </label>
                    <br />
                </>
            ))}
            <br /> */}
            <TextareaItem
                label="Details of the proposal"
                name="comments"
                updateInput={updateInfo}
                value={data.comments}
            />
            <MainButton
                disabled={stepDisabled()}
                onClick={() => setStep(REVIEW_STEP)}
            >
                Next
            </MainButton>
        </>
    )
}

export default EngagementInfoStep

const placesToExplore = [
    "Island Tour",
    "Buccoo Reef/Nylon Pool Tour",
    "Argyle Waterfall Tour",
    "Scuba Diving",
    "Birdwatching Tour",
    "Wildlife Tour",
    "Natural History Tour",
    "Mountain Bike Tour",
    "Couple’s Spa Treatment",
    "Private Sunset Dinner on the Beach",
    "Dinner at the Golf Gazebo",
    // "Other",
]
