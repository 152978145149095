import React from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { Title } from "./styles"
import scrollTo from "gatsby-plugin-smoothscroll"

const EditButton = styled.button`
    background: none;
    border: none;
    text-decoration: underline;
    color: ${colors.primary};
    font-size: 20px;
    margin-top: 15px;
`

const ReviewStep = ({ data, setStep, loading }) => {
    return (
        <>
            <Title>Review Information:</Title>
            {data.map((item, index) => (
                <div style={{ marginBottom: 30 }}>
                    {Object.values(item.data).map((info, index) => (
                        <p
                            style={{
                                color: "white",
                                marginBottom: 4,
                            }}
                        >
                            {info}
                        </p>
                    ))}
                    <EditButton
                        type="button"
                        onClick={() => {
                            scrollTo("#meetingForm")
                            setStep(item.step)
                        }}
                    >
                        Edit information
                    </EditButton>
                </div>
            ))}

            {/* <Title style={{ marginTop: 30 }}>Review Event Information:</Title>
            {Object.values(eventDetails).map((info, index) => (
                <p
                    style={{
                        color: "white",
                        marginBottom: 4,
                    }}
                >
                    {info}
                </p>
            ))}
            <EditButton
                type="button"
                onClick={() => {
                    scrollTo("#meetingForm")
                    setStep(EVENT_INFO_STEP)
                }}
            >
                Edit event information
            </EditButton> */}
            {/* <br /> */}
            {/* <br /> */}
            <br />
            <MainButton
                disabled={loading}
                style={{ marginBottom: 20 }}
                type="submit"
            >
                Submit
            </MainButton>
        </>
    )
}

export default ReviewStep
