import React from "react"
import "./layout.css"
import PropTypes from "prop-types"
import styled from "styled-components"

import Navbar from "./layout/navbar"
import Footer from "./layout/footer"
import RomanceForm from "./forms/romance-form"
import MeetingsForm from "./forms/meetings-form"
import WeddingsForm from "./forms/weddings-form"
import { useRecoilValue } from "recoil"
import {
    showingMeetingForm,
    showingMobileNav,
    showingRomanceForm,
    showingWeddingForm,
} from "../state/atoms"
import MobileNavbar from "./layout/mobile-navbar"
import { useStaticQuery } from "gatsby"
const Main = styled.main`
    color: #08424d;
`
export const MaxWidthContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
`

const Layout = ({ children, noMargin }) => {
    // const data = useStaticQuery(graphql`
    //     query FooterQuery {
    //         footerData: wpPage(title: {eq: "Footer"}) {
    //             footer {
    //                 logos {
    //                     sourceUrl
    //                 }
    //             }
    //           }
    //     }
    // `)

    // const footerLogos = data.footerData?.footer?.logos ?? []

    const showMeetingForm = useRecoilValue(showingMeetingForm)
    const showRomanceForm = useRecoilValue(showingRomanceForm)
    const showMobileNav = useRecoilValue(showingMobileNav)
    const showWeddingForm = useRecoilValue(showingWeddingForm)

    return (
        <>
            {showMeetingForm && <MeetingsForm />}
            {showRomanceForm && <RomanceForm />}
            {/* <RomanceForm /> */}
            {showWeddingForm && <WeddingsForm />}
            {showMobileNav && <MobileNavbar />}
            <Navbar />
            <Main>{children}</Main>
            <Footer noMargin={noMargin} />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
