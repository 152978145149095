import { Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { MainButton } from "../../styles/buttons"
import { colors } from "../../styles/colors"
import { Flex } from "../reusables"
import MediaQuery from "react-responsive"
import { useMedia } from "react-use"
import { useRecoilState, useSetRecoilState } from "recoil"
import { headerHeight, showingMobileNav } from "../../state/atoms"
import { BOOKING_LINK } from "../../project-constants/constants"
import HamburgerMenu from "react-hamburger-menu"

const Container = styled(Flex)`
    background: white;
    width: 100%;
    padding: 30px 20px;
    position: sticky;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    @media (max-width: 900px) {
        padding: 15px 20px;
    }
`
const InnerContainer = styled(Flex)`
    background: white;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`
const Menu = styled(Flex)`
    flex: 1;
`

const Logo = styled.img`
    max-width: 225px;
    margin: 0 auto;
    flex: 2;

    @media (max-width: 980px) {
        max-width: 160px;
    }
    @media (max-width: 900px) {
        max-width: 180px;
    }
`
const MenuItem = styled(Link)`
    font-size: 12px;
    text-transform: uppercase;
    list-style: none;
    font-weight: 500;
    white-space: nowrap;
    text-decoration: none;
    color: ${colors.copy};
    margin: 0 10px;

    &.${props => props.activeClassName} {
        border-bottom: 2px solid ${colors.primary};
    }
`

const MenuContainer = styled.div`
    position: absolute;
    right: 10px;
`
const Navbar = () => {
    //refs
    const headerRef = useRef()

    const isDesktop = useMedia("(min-width: 900px)")
    const [showNav, setShowNav] = useRecoilState(showingMobileNav)
    const setHeaderHeight = useSetRecoilState(headerHeight)

    useEffect(() => {
        if (typeof window !== "undefined") {
            const height = document.getElementById("navHeader").clientHeight
            setHeaderHeight(height)
            // console.log("Set height: " + height)
        }
    }, [setHeaderHeight])

    return (
        <Container id="navHeader" ref={headerRef}>
            <InnerContainer
                justify={isDesktop ? "space-between" : "center"}
                align="center"
            >
                <MediaQuery minDeviceWidth={900}>
                    <Menu align="center">
                        <MenuItem activeClassName="active" to="/rooms">
                            Rooms
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/romance">
                            Romance
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/activities">
                            Activities
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/golf">
                            Golf
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/property">
                            Property
                        </MenuItem>
                    </Menu>
                </MediaQuery>
                <ImageLink to="/">
                    <Logo src={require("../../images/logo.svg")} />
                </ImageLink>
                <MediaQuery minDeviceWidth={900}>
                    <Menu justify="space-between" align="center">
                        <MenuItem activeClassName="active" to="/specials">
                            Specials
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/meetings">
                            Meetings & Events
                        </MenuItem>
                        <MenuItem activeClassName="active" to="/eat-and-drink">
                            Eat & Drink
                        </MenuItem>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={BOOKING_LINK}
                            style={{ display: "inline-block" }}
                        >
                            <MainButton
                                style={{
                                    fontSize: 13,
                                    paddingVertical: 4,
                                    paddingHorizonta: 3,
                                }}
                            >
                                Book
                            </MainButton>
                        </a>
                        {/* <MenuItem to="/">Book</MenuItem> */}
                    </Menu>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={900}>
                    <MenuContainer>
                        <HamburgerMenu
                            isOpen={showNav}
                            menuClicked={() => setShowNav(!showNav)}
                            width={23}
                            height={15}
                            strokeWidth={2}
                            rotate={0}
                            color={colors.primary}
                            borderRadius={0}
                            animationDuration={0.5}
                        />
                    </MenuContainer>
                </MediaQuery>
            </InnerContainer>
        </Container>
    )
}

export default Navbar

const ImageLink = styled(Link)`
    margin: 0 auto;
    flex: 1;

    @media (max-width: 900px) {
        flex: none;
    }
`
